import { template as template_ced8f1e8e6d947db9163387356c5ce02 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_ced8f1e8e6d947db9163387356c5ce02(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
